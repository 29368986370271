<template>
  <v-row 
    v-if="editedItem"
    justify="center" 
    style="padding-top: 10px;" 
  >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding: 10px 20px;"
                >
                  <translate
                    v-model="editedItem.Title"
                    label="T�tulo"
                    :languages="languages"
                    type="text"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding: 10px 20px;"
                >
                  <translate
                    v-model="editedItem.Place"
                    label="Lugar"
                    :languages="languages"
                    type="text"
                  />
                </v-col>
                <v-col
                  v-if="plan.category && plan.category.length > 0"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    item-value="id"
                    item-text="name.es"
                    :items="plan.category"
                    v-model="editedItem.CategoryID"
                    label="Categor�a"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="plan.subcategory && plan.subcategory.length > 0"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-if="plan.id === 'barcy-club'"
                    item-value="id"
                    item-text="name.es"
                    :items="prepareSubcategory(editedItem.CategoryID)"
                    v-model="editedItem.SubcategoryID"
                    label="Subcategor�a"
                    outlined
                    hide-details
                  />
                  <v-select
                    v-else
                    item-value="id"
                    item-text="name.es"
                    :items="plan.subcategory"
                    v-model="editedItem.SubcategoryID"
                    label="Subcategor�a"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="plan.weekday && editedItem.CategoryID !== 'happening'"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    item-value="id"
                    item-text="name.es"
                    :items="plan.weekday"
                    v-model="editedItem.WeekdayID"
                    label="D�a tem�tico"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding: 10px 20px;"
                >
                  <translate
                    v-model="editedItem.Description"
                    label="Descripci�n"
                    :languages="languages"
                    type="text"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  
                  <small>Imagen:</small>
                  <div style="padding:20px 10px 0 10px;">
                    <select-image 
                      :key="editedItem.lmu"
                      :image="editedItem.Image"
                      :defaultImage="editedItem.Image"
                      :onChange="handleChangeImage"
                      :locale="locale"
                      :folder="'ab-01-common'"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="editedItem.Reservable"
                    hideDetails
                    label="Reservable"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="editedItem.Reservable"
                >
                  <v-text-field
                    v-model="editedItem.PAX"
                    label="Cupo"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="editedItem.Reservable"
                >
                  <v-text-field
                    v-model="editedItem.PVPHosted"
                    label="PVP Alojado"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="editedItem.Reservable"
                >
                  <v-text-field
                    v-model="editedItem.PVPNoHosted"
                    label="PVP NO alojado"
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-col>
            <!--v-col
              cols="12"
              sm="12"
              md="6"
            >
              <availability
                :value="editedItem.Availabilities"
                :id="editedItem.ID"
                :locale="locale"
                :onChange="handleChangeAvailabilities"
                :timezone="timezone"
              />
            </v-col-->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <delete-item
            v-if="editedItem.ID"
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            
          />
          <v-btn
            @click="() => onClose(null)"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-row>
</template>
<script>
import api from '@/services/api'
//import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
import Translate from '@/components/Translate'
import DeleteItem from '@/components/DeleteItem' 
export default {
  components: {
    SelectImage,
    Translate,
    DeleteItem,
  },
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      requried: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    tmpSubcategoryID: null,
  }),
  computed: {
    languages () {
      return this.plan.languages
    },
  },
  watch : {
    value () {
      this.prepareValue()
    },
    'value.ID' () {
      this.prepareValue()
    },
    'editedItem.SubcategoryID' (v) {
      if (v) this.tmpSubcategoryID = v
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      const aux = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      this.tmpSubcategoryID = aux.SubcategoryID
      if (aux) aux.lmu = new Date().toISOString()
      this.editedItem = aux
    },
    prepareSubcategory (v) {
      if (v === 'happening') {
        this.editedItem.SubcategoryID = null
        return [{ id: null, name: { es: 'Especial' } }]
      } else {
        this.editedItem.SubcategoryID = this.tmpSubcategoryID
        return this.plan.subcategory
      }
    },
    handleChangeImage (v) {
      if (!v) return 

      this.editedItem.Image = v.base64
      this.editedItem.newImage = v
      this.editedItem.lmu = new Date().toISOString()
    },
    handleSave () {
      if (this.editedItem.CategoryID === 'happening') this.editedItem.WeekdayID = null

      if (this.editedItem.ID) {
        api.updateItem ('dviewcontent', `v1/private/activity-barcelo-01/custom-manager/${this.contentTypeID}/`, this.editedItem.ID, this.editedItem)
          .then(() => {   
            this.onClose(true)
          })
      } else {
        api.addItem ('dviewcontent', `v1/private/activity-barcelo-01/custom-manager/${this.contentTypeID}`, this.editedItem)
          .then(() => {   
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      if (!this.editedItem.ID) return
      api.deleteItem ('dviewcontent', `v1/private/activity-barcelo-01/custom-manager/${this.contentTypeID}/`, this.editedItem.ID)
        .then(() => {   
          this.onClose(true)
        })
    },
  },
}
</script>
